import {
  baseURL,
  idBaseURL,
  isSuccess,
  option,
  fetchData,
} from '../utils/fetch';
import * as actions from '../actions/user';
import { excludeImages } from '../utils/files';
import moment from 'moment';
import { getAuthUserPhoto } from './auth';
import { imageChangeHandler } from '../utils/resizeImage';
import { DEPARTMENT_ID_OTHER_DOCTOR } from '../constants/departments';

const SELECTED_BLANK_VALUE = 0;

/**
 * ユーザー情報の取得
 *
 * 引数に指定した uid のユーザーの情報を取得する。
 * 取得した後は、ユーザーデータ取得成功のアクションを送信する。
 * @param {string} uid - ユーザーID
 */
export const getUser = (uid) => async (dispatch) => {
  dispatch(actions.requestGetUser());
  const op = await option.json();
  return fetchData(idBaseURL() + `/users/${uid}/profile`, op)
    .then((response) => isSuccess(response))
    .then((json) => dispatch(actions.successGetUser(json.userData)))
    .catch((error) => dispatch(actions.failureGetUser(error.message)));
};

/**
 * ユーザー情報を編集する
 *
 * @param {object} params - APIに渡すパラメータ
 * @param {array} files - ファイルデータ配列
 */
export const putUser = (params, files) => async (dispatch) => {
  dispatch(actions.requestPutUser());
  const errors = validatePutUser(params, files);
  if (errors.length > 0) {
    dispatch(actions.validateErrorPutUer(errors));
  } else {
    /* 職務経歴のインデックスを再作成 */
    const jobHistory = params.jobHistory.map((value, index) => {
      return {
        ...value,
        viewIndex: index,
      };
    });
    const body = {
      selfIntroduction: params.selfIntroduction,
      firstName: params.firstName,
      lastName: params.lastName,
      gender: params.gender,
      departmentId: params.departmentId,
      otherDepartment: params.otherDepartment,
      hospitalName: params.hospitalName || null,
      hospitalId: params.hospitalId,
      trainingHospitalName: params.trainingHospitalName || null,
      trainingHospitalId: params.trainingHospitalId,
      prefectureId: params.prefectureId,
      universityName: params.universityName,
      twitterAccount: params.twitterAccount || null,
      highSchoolName: params.highSchoolName,
      jobHistory,
    };
    const op = await option.json('PATCH', body);
    return fetchData(baseURL() + 'users/profile', op)
      .then((response) => isSuccess(response))
      .then(() =>
        files && files.length === 1 ? uploadUserFile(files[0]) : null,
      )
      .then((result) => {
        dispatch(actions.successPutUser());
        if (result) {
          /* ヘッダ画像を変更するために画像を再取得 */
          dispatch(getAuthUserPhoto(result));
        }
      })
      .catch((error) => dispatch(actions.failurePutUser(error.message)));
  }
};

/**
 * 空・nullの確認
 *
 * @param {string} str - 確認テキスト
 * @returns {boolean} - 判定結果(true:未入力/false:入力)
 */
function isBlank(str) {
  return !str || str.trim() === '';
}

/**
 * ユーザー情報編集を検証する
 *
 * @param {object} params - APIに渡すパラメータ
 * @param {array} files - ファイルデータ配列
 */
function validatePutUser(params, files) {
  const errors = [];
  const length = 255;
  const selfIntroductionLength = 100;
  const size = 10 * 1024 * 1024; // 10MB
  const dateFormat = 'YYYY-MM-DD';
  if (
    params.selfIntroduction &&
    params.selfIntroduction.length > selfIntroductionLength
  ) {
    errors.push('OVER_SELF_INTRODUCTION');
  }
  if (isBlank(params.firstName)) {
    errors.push('EMPTY_FIRST_NAME');
  }
  if (params.firstName.length > length) {
    errors.push('OVER_FIRST_NAME');
  }
  if (isBlank(params.lastName)) {
    errors.push('EMPTY_LAST_NAME');
  }
  if (params.lastName.length > length) {
    errors.push('OVER_LAST_NAME');
  }
  if (
    !Number.isInteger(params.departmentId) ||
    params.departmentId === SELECTED_BLANK_VALUE
  ) {
    errors.push('EMPTY_DEPARTMENT_CODE');
  }
  if (
    params.departmentId === DEPARTMENT_ID_OTHER_DOCTOR &&
    isBlank(params.otherDepartment)
  ) {
    errors.push('EMPTY_OTHER_DEPARTMENT');
  }
  if (params.otherDepartment && params.otherDepartment.length > length) {
    errors.push('OVER_OTHER_DEPARTMENT');
  }
  if (!params.hospitalName && !params.universityName) {
    errors.push('EMPTY_BOTH_HOSPITAL_AND_UNIVERSITY_NAME');
  }
  if (params.hospitalName && params.hospitalName.length > length) {
    errors.push('OVER_HOSPITAL_NAME');
  }
  if (params.universityName && params.universityName.length > length) {
    errors.push('OVER_UNIVERSITY_NAME');
  }
  if (params.twitterAccount) {
    if (params.twitterAccount.length < 4 || params.twitterAccount.length > 15) {
      errors.push('INVALID_TWITTER_ACCOUNT_LENGTH');
    }
    if (params.twitterAccount.match(/[^a-zA-Z0-9_]/)) {
      errors.push('INVALID_TWITTER_ACCOUNT_CHAR_TYPE');
    }
  }
  if (
    params.trainingHospitalName &&
    params.trainingHospitalName.length > length
  ) {
    errors.push('OVER_TRAINING_HOSPITAL_NAME');
  }
  if (
    !Number.isInteger(params.prefectureId) ||
    params.prefectureId === SELECTED_BLANK_VALUE
  ) {
    errors.push('EMPTY_PREFECTURE_ID');
  }
  if (params.highSchoolName && params.highSchoolName.length > length) {
    errors.push('OVER_HIGH_SCHOOL_NAME');
  }
  if (params.jobHistory && params.jobHistory.length > 0) {
    params.jobHistory.forEach((jobHistory, i) => {
      if (
        jobHistory.hospitalName === '' ||
        jobHistory.hospitalName === undefined
      ) {
        errors.push(`EMPTY_JH_${i}_HOSPITAL_NAME`);
      } else if (jobHistory.hospitalName.length > length) {
        errors.push(`OVER_JH_${i}_HOSPITAL_NAME`);
      }
      if (jobHistory.startYear === '' || jobHistory.startYear === undefined) {
        errors.push(`EMPTY_JH_${i}_START_YEAR`);
      } else if (
        jobHistory.startYear &&
        jobHistory.startMonth &&
        jobHistory.startDay
      ) {
        if (
          !moment(
            `${jobHistory.startYear}-${jobHistory.startMonth}-${jobHistory.startDay}`,
            dateFormat,
          ).isValid()
        ) {
          errors.push(`INVALID_JH_${i}_START_DATE`);
        }
      }
      if (jobHistory.endYear && jobHistory.endMonth && jobHistory.endDay) {
        if (
          !moment(
            `${jobHistory.endYear}-${jobHistory.endMonth}-${jobHistory.endDay}`,
            dateFormat,
          ).isValid()
        ) {
          errors.push(`INVALID_JH_${i}_END_DATE`);
        }
      }
    });
  }
  if (files) {
    if (excludeImages(files).length > 0) {
      errors.push('INVALID_IMAGE_TYPE');
    } else {
      files.forEach(
        (file) => file.size > size && errors.push('OVER_PROFILE_IMAGE'),
      );
    }
  }
  return errors;
}

/**
 * プロフィール画像のアップロード
 *
 * @param {array} files
 * @return {Promise<void>}
 */
export const uploadUserFiles = async (files) => {
  for (let i = 0; i < files.length; i++) {
    await uploadUserFile(files[i]);
  }
};

/**
 * プロフィール画像のアップロード
 *
 * @param {File} file アップロード対象ファイルデータ
 * @return {Promise}
 */
const uploadUserFile = async (file) => {
  const maxSize = 300;
  const result = await imageChangeHandler(file, maxSize);

  const formData = new FormData();
  formData.append('photo', result, result.name);
  const op = await option.multipart(formData, 'PUT');
  return fetchData(baseURL() + 'users/profile/avatar', op)
    .then((response) => isSuccess(response))
    .then((json) => json.profilePhotoUrl)
    .catch((error) => error.message);
};

/**
 * ユーザーのフォロー
 *
 * @param {string} uid - ユーザーID
 */
export const followUser = (uid) => async (dispatch) => {
  const op = await option.json('POST');
  return fetchData(baseURL() + `users/${uid}/follow`, op)
    .then((response) => isSuccess(response))
    .then(() => dispatch(actions.followUser()))
    .catch((error) => error.message);
};

/**
 * ユーザーのフォロー解除
 *
 * @param {string} uid - ユーザーID
 */
export const unfollowUser = (uid) => async (dispatch) => {
  const op = await option.json('DELETE');
  return fetchData(baseURL() + `users/${uid}/follow`, op)
    .then((response) => isSuccess(response))
    .then(() => dispatch(actions.unfollowUser()))
    .catch((error) => error.message);
};
