import {
  baseURL,
  idBaseURL,
  isSuccess,
  option,
  fetchData,
} from '../utils/fetch';
import * as actions from '../actions/auth';
import { downloadFile } from './images';
import * as arrayUtil from '../utils/array';
import { setUserId } from '../utils/analytics';
import { LogRocketService } from '../services/logRocket';

/**
 * 仮ユーザーの作成
 * @returns {function(*): Promise<boolean | Promise<boolean>>}
 */
export const setPreRegister = () => async (dispatch) => {
  // 医師招待機能廃止に伴い、isTrialとtokenは固定値を指定。
  // TODO API側でパラメーターの利用を廃止したら、Web側からパラメータを送ることも廃止する。
  const body = {
    isTrial: false,
    token: null,
  };
  const op = await option.json('POST', body);
  return fetchData(baseURL() + 'users/preregister', op)
    .then((response) => isSuccess(response))
    .then((json) => dispatch(actions.successSetPreRegister(json.userId)))
    .catch((error) => dispatch(actions.failureSetPreRegister(error.message)));
};

/**
 * 外部向けIDの設定
 *
 * 現在ログインしているユーザーの外部向けIDを取得して、
 * Reducerに設定のアクションを送信する。
 *
 * @returns {Function}
 */
export const setDiscloseId = () => async (dispatch) => {
  const op = await option.json();
  return fetchData(baseURL() + 'users/disclose-id', op)
    .then((response) => isSuccess(response))
    .then((json) => {
      dispatch(actions.successSetDiscloseId(json.userId));
      // GAへ送信するトラッキング情報にuserIdを設定する
      setUserId(json.userId);
      // initしていればLogRocketへ送信するユーザー名としてuserIdを設定
      LogRocketService.setUser(json.userId);
    })
    .catch((error) => error.message);
};

/**
 * 認証ユーザー情報の取得
 * @param {string} discloseId - 外部向けID
 * @returns {function(*=): Promise}
 */
export const getAuthUser = (discloseId) => async (dispatch) => {
  const op = await option.json();
  return fetchData(idBaseURL() + `/users/${discloseId}/profile`, op)
    .then((response) => isSuccess(response))
    .then((json) => dispatch(actions.successGetAuthUser(json.userData)))
    .catch((error) => error.message);
};

/**
 * ログインユーザーの写真を取得
 * @param photoUrl
 * @returns {function(*): (*|Promise<*>)}
 */
export const getAuthUserPhoto = (photoUrl) => async (dispatch) => {
  if (!photoUrl) {
    return;
  }
  const blobUrl = await downloadFile(photoUrl);
  dispatch(actions.successGetAuthUserPhoto(blobUrl));
};

/**
 * 権限の取得
 * @returns {function(*): Promise<Promise<any | never>>}
 */
export const getPermissions = async (dispatch) => {
  const op = await option.json();
  return fetchData(baseURL() + 'users/permissions', op)
    .then((response) => isSuccess(response))
    .then((json) => {
      dispatch(actions.successGetPermissions(json.permissions));
      return json.permissions;
    })
    .catch((error) => {
      dispatch(actions.failureGetPermissions(error.message));
      return null;
    });
};

/**
 * 認可の判定
 *
 * 引数に指定した permissions について、指定した動作を認可しているかを判定する。
 *
 * @param {array} permissions - 認可オブジェクト
 * @param {string} groupId - グループID文字列
 * @param {string} target - 対象文字列(例: 'question','group_join_request',...)
 * @param {string} operation - 動作文字列(例: 'read','create',...)
 * @param {string} option - オプション文字列(例: 'allow_others')
 * @returns {boolean} - 判定結果(true:認可/false:認可していない)
 */
export const hasPermission = (
  permissions,
  groupId,
  target,
  operation,
  option = '',
) => {
  if (arrayUtil.isEmpty(permissions) || !groupId || !target || !operation) {
    return false;
  }
  return permissions.some((p) => {
    if (
      p.groupId === groupId &&
      p.target === target &&
      p.operation === operation
    ) {
      if (option) {
        return p.option === option;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });
};

/**
 * 認可グループID配列の取得
 *
 * @param {array} permissions - 認可オブジェクト
 * @param {string} target - 対象文字列(例: 'question','group_join_request',...)
 * @param {string} operation - 動作文字列(例: 'read','create',...)
 * @param {string} option - オプション文字列(例: 'allow_others')
 * @returns {[]|*} - 認可されたグループID配列
 */
export const getPermittedGroupIds = (
  permissions,
  target,
  operation,
  option = '',
) => {
  const result = [];
  if (arrayUtil.isEmpty(permissions) || !target || !operation) {
    return result;
  }
  return permissions
    .filter((p) => {
      if (p.target === target && p.operation === operation) {
        if (option) {
          return p.option === option;
        } else {
          return true;
        }
      } else {
        return false;
      }
    })
    .map((p) => p.groupId);
};

/**
 * ユーザーステータスの取得
 *
 * @returns {string} userStatus - ログインしているユーザーのステータス
 */
export const fetchUserStatus = async () => {
  const op = await option.json();
  /**
   * fetchData関数で権限エラーがレスポンスされた場合、fetchUserStatus（本関数）を呼び出している。
   * 再帰呼び出ししないようにするため、fetchData関数の第3引数(skipJudgeForbidden)にtrueを指定する。
   */
  return fetchData(baseURL() + 'users/user-status', op, true)
    .then(async (response) => {
      const { userStatus } = await response.json();
      return userStatus;
    })
    .catch((error) => error.message);
};

/**
 * ユーザーステータスの取得とredux stateに保存
 */
export const setUserStatus = () => async (dispatch, getState) => {
  const userStatus = await fetchUserStatus();
  const user = { ...getState().auth.user, userStatus };
  dispatch(actions.successGetAuthUser(user));
};

/**
 * ユーザにメールアドレス更新認証用メールを送信
 */
export const sendUpdateEmailVerificationMail = () => async (
  dispatch,
  getState,
) => {
  const op = await option.json('POST');
  return fetchData(baseURL() + 'users/verify-update-email', op)
    .then((response) => {
      if (response.status == 429) {
        throw new TooManyRequestsError(response);
      }
      return response;
    })
    .then((response) => isSuccess(response))
    .then(() => dispatch(actions.successSendUpdateEmailVerificationMail()))
    .catch(async (error) => {
      if (error instanceof TooManyRequestsError) {
        const res = await error.response.json();
        const tooManyRequestsError = res.resultInfo.messageInfo.details.some(
          (detail) => detail.id == 'I10480005',
        );
        if (tooManyRequestsError) {
          dispatch(actions.failureEmailVerificationMailUpdateTooManyRequests());
        } else {
          dispatch(
            actions.failureSendUpdateEmailVerificationMail(
              res.resultInfo.message,
            ),
          );
        }
      } else {
        dispatch(actions.failureSendUpdateEmailVerificationMail(error.message));
      }
    });
};

/**
 * ユーザにメールアドレス認証用メールを送信
 */
export const sendVerificationMail = () => async (dispatch, getState) => {
  const op = await option.json('POST');
  return fetchData(baseURL() + 'users/verify-email', op)
    .then((response) => {
      if (response.status == 429) {
        throw new TooManyRequestsError(response);
      }
      return response;
    })
    .then((response) => isSuccess(response))
    .then(() => dispatch(actions.successSendVerificationMail()))
    .catch(async (error) => {
      if (error instanceof TooManyRequestsError) {
        const res = await error.response.json();
        const tooManyRequestsError = res.resultInfo.messageInfo.details.some(
          (detail) => detail.id == 'I10480005',
        );
        if (tooManyRequestsError) {
          dispatch(actions.failureEmailVerificationTooManyRequests());
        } else {
          dispatch(actions.failureSendVerificationMail(res.resultInfo.message));
        }
      } else {
        dispatch(actions.failureSendVerificationMail(error.message));
      }
    });
};

class TooManyRequestsError {
  constructor(response) {
    this.response = response;
  }
}
